import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { media } from "~/styles/breakpoints"
import SEO from "~/components/seo"
import Header from "~/components/Header"
import Container from "~/components/Container"
import Button from "~/components/Button"
import { Intro, IntroText, IntroImage } from "~/components/Layout"
import {
  PrimaryTitle,
  SecondaryTitle,
  Lead,
  SmallParagraph,
} from "~/components/Typography"
import ReactMarkdown from "react-markdown"
import pageData from "../../content/pages/stories.json"

export const pageQuery = graphql`
  query {
    categories: allMarkdownRemark(
      filter: { frontmatter: { layout: { eq: "story-category" } } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      nodes {
        frontmatter {
          title
        }
      }
    }
    stories: allMarkdownRemark(
      filter: { frontmatter: { layout: { eq: "story" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      nodes {
        frontmatter {
          title
          date(formatString: "MMMM D, YYYY")
          category
          image
        }
        html
      }
    }
  }
`

export default ({
  data: {
    categories: { nodes: categoryList },
    stories: { nodes: stories },
  },
}) => {
  const { metaTitle, metaDescription, metaImage, title, lead } = pageData

  const pageLimiter = 5
  const [activeCategory, setActiveCategory] = useState("All")
  const [storiesPerPage, setStoriesPerPage] = useState(pageLimiter)
  const categories = [
    ...["All"],
    ...categoryList.map(item => item.frontmatter.title),
  ]

  const filteredStories =
    activeCategory === "All"
      ? stories
      : stories.filter(story =>
          story.frontmatter.category.includes(activeCategory)
        )

  const filteredStoriesPaged = filteredStories.slice(0, storiesPerPage)

  function handleButtonMoreClick() {
    setStoriesPerPage(storiesPerPage + pageLimiter)
  }

  return (
    <>
      <SEO title={metaTitle} description={metaDescription} image={metaImage} />
      <Header />
      <Wrapper>
        <Intro gradient="#fafae9" gradientFixed>
          <Container>
            <Title>{title}</Title>
            <IntroText>
              <Lead>
                <ReactMarkdown
                  children={lead}
                  disallowedElements={["p"]}
                  unwrapDisallowed
                />
              </Lead>
              <IntroImage border src="/images/madagascar.jpg" />
              <CategoryList>
                {categories.map((category, index) => (
                  <CategoryListItem
                    className={category === activeCategory ? "-active" : ""}
                    onClick={() => {
                      setActiveCategory(category)
                    }}
                    key={index}
                  >
                    {category}
                  </CategoryListItem>
                ))}
              </CategoryList>
            </IntroText>
            <StoryList>
              {filteredStoriesPaged.map(
                (
                  {
                    frontmatter: { category, date, image, layout, title },
                    html,
                  },
                  index
                ) => (
                  <Story key={index}>
                    <SecondaryTitle>{title}</SecondaryTitle>
                    <SmallParagraph>
                      Categories:{" "}
                      {category.map((name, index) => (
                        <React.Fragment key={index}>
                          {name}
                          {index !== category.length - 1 && ", "}
                        </React.Fragment>
                      ))}
                      <span className="spacer"></span> Added on {date}
                    </SmallParagraph>
                    <StoryContent dangerouslySetInnerHTML={{ __html: html }} />
                    {image && <StoryImage src={image} loading="lazy" />}
                  </Story>
                )
              )}
              {filteredStories.length > filteredStoriesPaged.length && (
                <Button
                  outline
                  label="Show more stories"
                  icon="arrow-down"
                  onClick={() => handleButtonMoreClick()}
                />
              )}
            </StoryList>
          </Container>
        </Intro>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div``

const Title = styled(PrimaryTitle)`
  margin-bottom: 50px;
  background: -webkit-linear-gradient(#01947d, #395489);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const CategoryList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 100px 0 0;
  position: relative;
  padding: 60px 25px 17px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04), 0 2px 2px rgba(0, 0, 0, 0.04),
    0 4px 4px rgba(0, 0, 0, 0.04), 0 6px 8px rgba(0, 0, 0, 0.04),
    0 8px 16px rgba(0, 0, 0, 0.04);
  user-select: none;

  &::before {
    content: "Select category";
    opacity: 0.4;
    position: absolute;
    top: 20px;
    left: 25px;
    font-size: 16px;
  }
`

const CategoryListItem = styled.li`
  margin: 0 3px 3px 0;
  padding: 8px 10px;
  cursor: pointer;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 0.05em;
  color: #829794;
  transition: 0.15s;

  &:not(.-active):hover {
    background: #829794;
    color: #fff;
  }

  &.-active {
    background: rgba(0, 0, 0, 0.1);
  }
`

const StoryList = styled.div`
  margin-top: 150px;

  ${media.tablet} {
    margin-top: 160px;
  }
`

const Story = styled.article`
  position: relative;

  &:not(:last-child) {
    margin-bottom: 150px;
  }

  ${media.tablet} {
    padding-right: 35%;

    @media (max-width: 1330px) {
      padding-right: 40%;
    }
  }
`

const StoryContent = styled.div`
  p {
    margin-bottom: 1em;

    a {
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      transition: 0.15s;

      &:hover {
        color: #829794;
      }
    }
  }
`

const StoryImage = styled.img`
  width: 100%;
  margin-top: 40px;

  ${media.tablet} {
    width: 30%;
    margin-top: 0;
    position: absolute;
    top: 90px;
    right: 0;
  }
`
